<template>
  <v-app>
    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- <div id="nav">
      <router-link to="/">Splash</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->

      <!-- Provides the application the proper gutter -->
      <v-container fluid>

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import {
    Auth
  } from "aws-amplify";

  export default {
    data: () => ({

    }),

    methods: {
      async GetUserInfo() {
        await Auth.currentAuthenticatedUser({
          bypassCache: true,
        }).then(user => {
          // console.log('attributes:', user.attributes);


          if (user.attributes['custom:form_profile'] == '1') {
            if (user.attributes['custom:form_business'] == '1') {
              if (user.attributes['custom:form_bank'] == '1' || user.attributes['custom:form_bank'] == '0.5') {
                this.$router.push({
                  name: 'Overview'
                }).catch(() => {})
              } else {
                this.$router.push({
                  name: 'BankWelcome'
                }).catch(() => {})
              }
            } else {

              this.$router.push({
                name: 'BusinessWelcome'
              }).catch(() => {})

            }
          } else {
            this.$router.push({
              name: 'Welcome'
            }).catch(() => {})
          }


        }).catch(error => {
          if (error === "The user is not authenticated") {
            this.$router.push({
              name: 'Splash'
            }).catch(() => {})
          } else {
            console.log(error);
          }
        });

      }
    },

    created() {
      // console.log('created')
      this.GetUserInfo();
    },
  }
</script>


<style scoped>
  * {
    line-height: 1.2;
  }

  .container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    max-width: 400px;
    margin: 0 auto;
  }
</style>