import Vue from 'vue'
import VueRouter from 'vue-router'
import Splash from '../views/Splash.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Splash',
    component: Splash
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import( /* webpackChunkName: "about" */ '../views/Signup.vue')
  },
  {
    path: '/signin',
    name: 'Signin',
    component: () => import( /* webpackChunkName: "about" */ '../views/Signin.vue')
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: () => import( /* webpackChunkName: "about" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/confirmemail',
    name: 'ConfirmEmail',
    component: () => import( /* webpackChunkName: "about" */ '../views/ConfirmEmail.vue')
  },
  {
    path: '/confirmemail',
    name: 'ConfirmEmailLogin',
    component: () => import( /* webpackChunkName: "about" */ '../views/ConfirmEmailLogin.vue')
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import( /* webpackChunkName: "about" */ '../views/Welcome.vue')
  },
  {
    path: '/profile',
    name: 'ProfileSetup',
    component: () => import( /* webpackChunkName: "about" */ '../views/ProfileSetup.vue')
  },
  {
    path: '/business-welcome',
    name: 'BusinessWelcome',
    component: () => import( /* webpackChunkName: "about" */ '../views/BusinessWelcome.vue')
  },
  {
    path: '/business-profile',
    name: 'BusinessSetup',
    component: () => import( /* webpackChunkName: "about" */ '../views/BusinessSetup.vue')
  },
  {
    path: '/stitch',
    name: 'BankWelcome',
    component: () => import( /* webpackChunkName: "about" */ '../views/BankWelcome.vue')
  },
  {
    path: '/bank-info',
    name: 'BankInfo',
    component: () => import( /* webpackChunkName: "about" */ '../views/BankInfo.vue')
  },
  {
    path: '/bank-success',
    name: 'BankSuccess',
    component: () => import( /* webpackChunkName: "about" */ '../views/BankSuccess.vue')
  },
  {
    path: '/successful',
    name: 'Successful',
    component: () => import( /* webpackChunkName: "about" */ '../views/Successful.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import( /* webpackChunkName: "about" */ '../views/Dashboard.vue'),
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'transactions',
        name: 'Transactions',
        component: () => import( /* webpackChunkName: "about" */ '../views/dashboard/Transactions.vue')
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'overview',
        name: 'Overview',
        component: () => import( /* webpackChunkName: "about" */ '../views/dashboard/Overview.vue')
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'analytics',
        name: 'Analytics',
        component: () => import( /* webpackChunkName: "about" */ '../views/dashboard/Analytics.vue')
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'profile',
        name: 'Profile',
        component: () => import( /* webpackChunkName: "about" */ '../views/dashboard/Profile.vue')
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'peronal-profile',
        name: 'Personal',
        component: () => import( /* webpackChunkName: "about" */ '../views/profile/Personal.vue')
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'business-profile',
        name: 'Business',
        component: () => import( /* webpackChunkName: "about" */ '../views/profile/Business.vue')
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'bank-details',
        name: 'BankDetails',
        component: () => import( /* webpackChunkName: "about" */ '../views/profile/BankDetails.vue')
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'notifications',
        name: 'Notifications',
        component: () => import( /* webpackChunkName: "about" */ '../views/dashboard/Notifications.vue')
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'plan',
        name: 'Plan',
        component: () => import( /* webpackChunkName: "about" */ '../views/profile/Plan.vue')
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'support',
        name: 'Support',
        component: () => import( /* webpackChunkName: "about" */ '../views/profile/Support.vue')
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'support',
        name: 'Support',
        component: () => import( /* webpackChunkName: "about" */ '../views/profile/Support.vue')
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'settings',
        name: 'Settings',
        component: () => import( /* webpackChunkName: "about" */ '../views/profile/Settings.vue')
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'PIN',
        name: 'PIN',
        component: () => import( /* webpackChunkName: "about" */ '../views/settings/PIN.vue')
      },
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'Learn',
        name: 'Learn',
        component: () => import( /* webpackChunkName: "about" */ '../views/profile/Learn.vue')
      },
      
    ]
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  
  // scrollBehavior (to, from, savedPosition) {
  //   console.log(to, from, savedPosition)
  //   return { top: 0 }
  // }
})


export default router