<template>
    <v-container fluid>


        <!-- <h1>WELCOME TO</h1> -->
        <img class="logo" :src="require('../assets/Moya_logo.svg')" alt="">

        <!-- <img class="splash" :src="require('../assets/splash.svg')" alt=""> -->
        <h4>Smart money manager for freelancers.</h4>


        <v-btn block @click="Signin()">Sign in</v-btn>
        <p>Do not have an account? <span>
                <router-link to="/about">Sign up here</router-link>
            </span></p>

    </v-container>
</template>

<script>
    export default {
        data: () => ({

        }),

        created() {

        },

        methods: {
            Signin() {
                this.$router.push({
                    name: 'Signin'
                }).catch(() => {})
            },
        },

        computed: {

        }
    }
</script>

<style scoped>
    .container {
        /* background-color: #5271ff; */
        width: 100%;
        height: 100%;
        padding: 20px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        background-color: rgb(232, 233, 255);
        background-size: contain;
        background-repeat: no-repeat;

        background-image: url('../assets/Moya__WelcomeScreen_SplashImage.png'), linear-gradient(180deg, rgba(206,210,254,1) 0%, rgba(243,245,250,1) 100%);

    }



    h1 {
        
        text-align: center;
        /* color: #fff; */
        font-size: 2.5em;
    }

    .logo {
        width: 80%;
        margin-top: 0px;
        margin-top: 70%;

    }

    .splash {
        width: 250px;
        margin: 30px;
    }

    h4 {
        /* color: #fff; */
        text-align: center;
        font-size: 1em;
        font-weight: bold;
        color: #1a1c76;
        width: 55%;
        margin: 10% 0;
    }

    .v-btn {
        align-items: center;
        border-radius: 20px;
        display: inline-flex;
        flex: 0 0 auto;
        text-transform: none;
        border: none;
        background-color: #5271ff !important;
        color: #fff !important;
        height: 40px !important;
        margin: 20px 0 0px 0;
        box-shadow: none !important;
        border: none !important;
    }

    p {
        /* color: #fff; */
        text-align: center;
        margin-top: 10px;
        font-size: 0.9em;
        font-weight: bold;
    }

    p a {
        text-decoration: underline;
        /* color: #fff; */
    }
</style>