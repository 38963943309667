import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    transactionsData: null,
    bankData: null,
    businessID: null,
    selectedAccount: 0,
    filterDate: null,
    notification: true,
  },
  mutations: {
    TransactionsData(state, transactionsData) {
      state.transactionsData = transactionsData
    },

    BankData(state, bankData) {
      state.bankData = bankData
    },

    BusinessID(state, businessID) {
      state.businessID = businessID
    },

    SelectedAccount(state, selectedAccount) {
      state.selectedAccount = selectedAccount
    },

    FilterDate(state, filterDate) {
      state.filterDate = filterDate
    },

    Notification(state, notification) {
      state.notification = notification
    }
  },
  actions: {
    TransactionsData({
      commit
    }, transactionsData) {
      commit('TransactionsData', transactionsData);
    },

    BankData({
      commit
    }, bankData) {
      commit('BankData', bankData);
    },

    BusinessID({
      commit
    }, businessID) {
      commit('BusinessID', businessID);
    },

    SelectedAccount({
      commit
    }, selectedAccount) {
      commit('SelectedAccount', selectedAccount);
    },

    FilterDate({
      commit
    }, filterDate) {
      commit('FilterDate', filterDate);
    },

    Notification({
      commit
    }, notification) {
      commit('Notification', notification);
    },
  },
  getters: {
    TransactionsData(state) {
      return state.transactionsData
    },

    BankData(state) {
      return state.bankData
    },

    BusinessID(state) {
      return state.businessID
    },

    SelectedAccount(state) {
      return state.selectedAccount
    },

    FilterDate(state) {
      return state.filterDate
    },

    Notification(state) {
      return state.notification
    }
  },
  modules: {
  }
})
